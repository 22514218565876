import React from 'react'
import MyActivityCard from '../../../components/myActivityCard/MyActivityCard'

const MyActivity = () => {
  return (
    <div>
      <MyActivityCard/>
      <MyActivityCard/>
      <MyActivityCard/>
      <MyActivityCard/>
    </div>
  )
}

export default MyActivity