export const activityNav = [
  {
    id: "1",
    heading: "My activity",
    to: "/activity/myactivity",
  },
  {
    id: "2",
    heading: "Following",
    to: "/activity/allactivity",
  },
  {
    id: "3",
    heading: "All activity",
    to: "/activity/follower",
  },
];
