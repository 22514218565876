


export const API_URL = "http://ec2-65-2-141-244.ap-south-1.compute.amazonaws.com:5000";

//export const API_URL = "http://localhost:5000";
// export const STRIPE_PUBLIC_KEY =
//   "pk_test_51Je5YVEZt19SYlupg9CdPIQG925M5DnFxDpEkx4bsI7rH3cSRa3vwzwYxPeGJTMOaTVfC3T4FqEKenIaYmAq1bUD00Wy2JTlPs";
// export const DAILY_BUDGET_PRICE = "price_1MKP72EZt19SYlupQrkCNjWz";
// export const DAILY_BUDGET_PRODUCT_ID = "prod_L1kXQit6EdA0p5";
